/*jslint browser: true, plusplus: true*/
/*global $, caddy, Chartist, NProgress, moment, Mustache, CONFIG */

window.caddy = {};


(function () {
  'use strict';
  caddy.debug = CONFIG.demo || false;
  caddy.scTablet = window.matchMedia("screen and (min-width: 768px)");
  caddy.scBig = window.matchMedia("screen and (min-width: 1280px)");
  caddy.endpointURL = CONFIG.endpointURL || 'http://dashboard.opencities.com/Endpoints/Analytics';
  caddy.lastLiveHash = 'nah man';
  caddy.evergreen = false;
  caddy.animateGraph = true;
  caddy.aPages = [];
  caddy.demoMinutes = 0;
  caddy.firstLiveCard = true;
  caddy.firstPopularDrawn = false;
  caddy.popularNow = [];
  caddy.data = {
    last48 : {
      endpoint: caddy.endpointURL + '/VisitorsLast48',
      debug: 'data/visitorslast48.json',
      response: 'handleLast48',
      frequency: CONFIG.frequency.chart || 360
    },
    contactMinutes : {
      endpoint: caddy.endpointURL + '/ContactMinutes',
      debug: 'data/contactminutes.json',
      response: 'handleContactMinutes',
      frequency: CONFIG.frequency.contactMinutes || 20
    },
    visitorsNow : {
      endpoint: caddy.endpointURL + '/VisitorsNow',
      debug: 'data/visitorsnow.json',
      response: 'handleVisitorsNow',
      frequency: CONFIG.frequency.visitorsNow || 30
    },
    popularToday : {
      endpoint: caddy.endpointURL + '/PopularPagesToday',
      debug: 'data/popularpagestoday.json',
      response: 'handlePopularToday',
      frequency: CONFIG.frequency.popularToday || 360
    },
    popularNow : {
      endpoint: caddy.endpointURL + '/PopularPagesNow',
      debug: 'data/popularpagesnow.json',
      response: 'handlePopularNow',
      frequency: CONFIG.frequency.popularNow || 30
    },
    livePage : {
      endpoint: caddy.endpointURL + '/LivePageViews?minutesAgo=' + Math.floor(CONFIG.frequency.livePageViews / 60),
      debug: 'data/livepageviews.json',
      response: 'handleLivePage',
      frequency: CONFIG.frequency.livePageViews || 30
    }
  };



  caddy.init = function () {
    browserDetection();
    applicationStartup();
    responsiveJS();
    chartToggle();
    panelTabs();
    initOdometers();
    animationEvents();

    NProgress.configure({ showSpinner: false });
    NProgress.start();

    var endpoint = caddy.data;
    caddy.getData(endpoint.last48);
    caddy.getData(endpoint.contactMinutes);
    caddy.getData(endpoint.visitorsNow);
    caddy.getData(endpoint.popularToday);
    caddy.getData(endpoint.popularNow);
    caddy.getData(endpoint.livePage);

  };

  caddy.handleLast48 = function (data) {
    var aData = data.Data,
      aTotal = [],
      aDesktop = [],
      aTablet = [],
      aMobile = [],
      aLabels = [],
      oChart,
      oHour,
      time,
      i;

    for (i = 0; i < aData.length; i++) {

      time = moment(aData[i].HourUtc).format('ha');

      oHour = {};
      oHour.value = aData[i].Visits.Total;
      oHour.meta = time;
      aLabels.push(oHour.meta);
      aTotal.push(oHour);

      oHour = {};
      oHour.value = aData[i].Visits.Desktop;
      oHour.meta = time;
      aDesktop.push(oHour);

      oHour = {};
      oHour.value = aData[i].Visits.Tablet;
      oHour.meta = time;
      aTablet.push(oHour);

      oHour = {};
      oHour.value = aData[i].Visits.Mobile;
      oHour.meta = time;
      aMobile.push(oHour);

    }

    oChart = {};
    oChart.name = 'Total';
    oChart.dataTotal = aTotal;
    oChart.dataDesktop = aDesktop;
    oChart.dataTablet = aTablet;
    oChart.dataMobile = aMobile;

    oChart.labels = aLabels;


    caddy.chartData = oChart;

    drawChart(oChart);

    NProgress.done();
  };

  caddy.handleContactMinutes = function (data) {

    var aTime = minsAndHours(data.Data);

    if (CONFIG.demo) {
      caddy.demoMinutes += 23;

      aTime = minsAndHours(data.Data + caddy.demoMinutes);
    }

    document.querySelector('.contact--hours').innerHTML = aTime[0];
    document.querySelector('.contact--minutes').innerHTML = aTime[1];

    setTimeout(function () {
      caddy.getData(caddy.data.contactMinutes);
    }, caddy.data.contactMinutes.frequency * 1000);

  };

  caddy.handlePopularToday = function (data) {


    var template = $('#card3').html(),
      firstCard,
      rendered;

    // Card List
    Mustache.parse(template);
    firstCard = data.Data.shift();
    rendered = Mustache.render(template, data);
    $('.panel--3 .card-list').html(rendered);


    // First Card
    template = $('#card3first').html();
    Mustache.parse(template);
    rendered = Mustache.render(template, firstCard);
    $('.panel--3 .card--first').html(rendered);

  };

  caddy.handlePopularNow = function (data) {


    var template = $('#card2').html(),
      firstCard,
      rendered,
      aData = data.Data.slice();


    if (CONFIG.demo) {
      data.Data = shuffleArray(data.Data);
      aData = data.Data.slice();



      data.Data[0].TotalViews = 130 + Math.floor(Math.random() * 10);
      data.Data[1].TotalViews = 118 + Math.floor(Math.random() * 10);
      data.Data[2].TotalViews = 103 + Math.floor(Math.random() * 10);
      data.Data[3].TotalViews = 91 + Math.floor(Math.random() * 10);
      data.Data[4].TotalViews = 77 + Math.floor(Math.random() * 10);
      data.Data[5].TotalViews = 63 + Math.floor(Math.random() * 10);
      data.Data[6].TotalViews = 51 + Math.floor(Math.random() * 10);
      data.Data[7].TotalViews = 39 + Math.floor(Math.random() * 10);
      data.Data[8].TotalViews = 23 + Math.floor(Math.random() * 10);
      data.Data[9].TotalViews = 7 + Math.floor(Math.random() * 10);
    }


    // Card List
    Mustache.parse(template);
    firstCard = data.Data.shift();
    rendered = Mustache.render(template, data);
    $('.panel--2 .card-list').html(rendered);


    // First Card
    template = $('#card2first').html();
    Mustache.parse(template);
    rendered = Mustache.render(template, firstCard);
    $('.panel--2 .card--first').html(rendered);


    // Show Up/Down Arrows


    $('[data-path]').each(function (index) {

      var dataPath = $(this).data('path'),
        self = this;

      if(caddy.popularNow.indexOf(dataPath) >= 0) {

        var oldValue = caddy.popularNow.indexOf(dataPath),
          newValue = index,
          title = $(this).find('.card__title').html();

        if (newValue > oldValue) {

          setTimeout(function () {
            $(self).find('.card-inner').addClass('fadeInDown');
            $(self).addClass('move-down');
          }, (index - 1)  * 150);

        } else if (oldValue > newValue) {

          setTimeout(function () {
            $(self).addClass('move-up');
            $(self).find('.card-inner').addClass('fadeInUp');
          }, (index - 1)  * 150);

        }

      }

      if (!caddy.firstPopularDrawn) {

        setTimeout(function () {
          $(self).find('.card-inner').addClass('fadeInDown');
        }, (index - 1)  * 150);
      } else {
        $(self).find('.card-inner').css('opacity', 1);
      }


      //console.log(caddy.popularNow[dataPath]);

    });

    caddy.firstPopularDrawn = true;

    caddy.popularNow = [];
    for (var i = 0; i < aData.length; i++) {
      caddy.popularNow.push(aData[i].Path);
    }



    setTimeout(function () {
      caddy.getData(caddy.data.popularNow);
    }, caddy.data.popularNow.frequency * 1000);

  };

  caddy.handleLivePage = function (data) {


    var page = data.Data[0],
      pages = data.Data;

    caddy.aPages = pages;

    if(CONFIG.demo) {

      // var items = ["Peach", "Mango", "Blueberry", "Nectarine", "Guava", "Strawberry", "Apple", "Kiwi Fruit"];

      // var devices = ["desktop", "tablet", "mobile"];

      // page.PageName = items[Math.floor(Math.random()*items.length)] + ' '+ (Math.floor(Math.random() * 5) + 1);

      // page.DeviceType = devices[Math.floor(Math.random()*devices.length)]

      //caddy.data.livePage.frequency = getRandomInt(2, 12);
      caddy.data.livePage.frequency = 60;
    }

    var aIntervals = getRandomIntervals(pages.length, caddy.data.livePage.frequency * 1000, true);

    for (var i = 0; i < pages.length; i++) {

      showPage(pages[i], aIntervals[i]);

    }


    // If no results, then query after 10 seconds
    if(pages.length == 0) {

     setTimeout(function () {
        caddy.getData(caddy.data.livePage);
      }, 10000);

    } else {


       setTimeout(function () {
        caddy.getData(caddy.data.livePage);
      }, caddy.data.livePage.frequency * 1000);
    }



  };

  caddy.handleVisitorsNow = function (data) {

    var oVisitors = data.Data,
      count = 0,
      desktop = Math.floor(oVisitors.Desktop / oVisitors.Total * 100) || 0,
      mobile = Math.floor(oVisitors.Mobile / oVisitors.Total * 100) || 0,
      tablet = 100 - desktop - mobile || 0;


    $('.v-card-bar').removeClass('active');
    if (desktop > 0) {
      count++;
      $('.v-card-bar--desktop').addClass('active');
    }
    if (tablet > 0) {
      count++;
      $('.v-card-bar--tablet').addClass('active');
    }

    if (mobile > 0) {
      count++;
      $('.v-card-bar--phone').addClass('active');
    }

    $('.v-card-bar').attr('data-devices', count);

    document.querySelector('.v-card__total').innerHTML = pad(oVisitors.Total);

    document.querySelector('.now--desktop').innerHTML = oVisitors.Desktop;
    document.querySelector('.now--tablet').innerHTML = (oVisitors.Total - oVisitors.Desktop - oVisitors.Mobile);
    document.querySelector('.now--phone').innerHTML = oVisitors.Mobile;

    document.querySelector('.v-card-bar--desktop').style.width = desktop + '%';
    document.querySelector('.v-card-bar--tablet').style.width = tablet + '%';
    document.querySelector('.v-card-bar--phone').style.width = mobile + '%';

    caddy.onlineNow = oVisitors.Total;

    setTimeout(function () {
      caddy.getData(caddy.data.visitorsNow);
    }, caddy.data.visitorsNow.frequency * 1000);

  };

  caddy.getData = function (data) {

    var handler = data.response;

    if (caddy.debug) {
      data.endpoint = data.debug;
    }

    $.ajax({
      method: "GET",
      url: data.endpoint,
      data: {
        id: CONFIG.dashboardId
      },
      success: function (data) {
        caddy[handler](data);
      }
    });
  };

  function drawChart(chartData) {
  ;

    var bSmallDevice = true,
      elChart = '.ct-chart',
      ratioCls = 'ct-major-seventh',
      aChartData = [],
      chart,
      oData;


    document.querySelector(elChart).classList.add(ratioCls);

    if (caddy.scTablet.matches) {
      bSmallDevice = false;
      document.querySelector(elChart).classList.remove(ratioCls);
    }


    // Chart Data
    if ($('.ct-chart').hasClass('chart--2')) {

      oData = {};
      oData.name = 'Desktop';
      oData.data = chartData.dataDesktop;
      aChartData.push(oData);

      oData = {};
      oData.name = 'Tablet';
      oData.data = chartData.dataTablet;
      aChartData.push(oData);

      oData = {};
      oData.name = 'Mobile';
      oData.data = chartData.dataMobile;
      aChartData.push(oData);

    } else {
      oData = {};
      oData.name = 'Total';
      oData.data = chartData.dataTotal;
      aChartData.push(oData);
    }



    chart = new Chartist.Line(elChart, {
      labels: chartData.labels,
      series: aChartData
    }, {
      low: 0,
      showArea: true,
      // lineSmooth: Chartist.Interpolation.simple({
      //   divisor: 2
      // }),
      height: (bSmallDevice ? '100%' : '350px'),
      chartPadding: {
        top: 20,
        right: 15,
        bottom: 5,
        left: 10
      },
      axisX: {
        offset: 30,
        labelInterpolationFnc: function (value, index) {

          var lblIndex = (bSmallDevice ? 8 : 4);

          return index % lblIndex === 0 ? value : '';
        }
      },
      axisY: {
        offset: (bSmallDevice ? 25 : 40),
        onlyInteger: (bSmallDevice ? true : false),
        labelInterpolationFnc: function (value, index) {

          if (bSmallDevice) {
            return abbrNum(value, 1);
          } else {
            return index % 2 === 0 ? abbrNum(value, 1) : null;
          }
        }
      },
      plugins: [
        Chartist.plugins.tooltip({
          appendToBody: true,
          pointClass: 'ct-circle'
        })
      ]
    });

    // Let's put a sequence number aside so we can use it in the event callbacks
    var seq = 0,
      delays = 80,
      durations = 400;

    chart.on('draw', function (data) {


      $('.ct-chart').removeClass('draw-lines');
      $('.ct-chart').removeClass('draw-circles')

      var delayoffset = 3000;

      if(data.type === 'line' || data.type === 'area') {

        if(!caddy.evergreen || !caddy.animateGraph) return false;

        seq++;

        //If the drawn element is a line we do a simple opacity fade in. This could also be achieved using CSS3 animations.
        data.element.animate({
          opacity: {
            // The delay when we like to start the animation
            begin: (data.type === 'area') ? 2600 : 1800,
            // Duration of the animation
            dur: durations,
            // The value where the animation should start
            from: 0,
            // The value where it should end
            to: 1
          }
        });
      }

      if (data.type === 'point') {

        seq++;

        var delay = (seq * delays) - 2000;

        if(!caddy.evergreen || !caddy.animateGraph) delay = 0;

        if (data.index === 0) {
          caddy.aPoints = [];
          caddy.chartOffset = $('.ct-chart').offset().left;

          setTimeout(function () {
              $('.ct-chart').addClass('draw-circles');
          }, delay)

        }

        var circle = new Chartist.Svg('circle', {
          cx: [data.x],
          cy: [data.y],
          r: [(bSmallDevice ? 3 : 5)],
          'ct:value': data.value.y,
          'ct:meta': data.meta,
        }, 'ct-circle pt-' + data.index);
        data.element.replace(circle);

        caddy.aPoints.push(data.x);

        if(!caddy.evergreen || !caddy.animateGraph) return false;

        circle.animate({
          x1: {
            begin: delay,
            dur: durations,
            from: data.x - 10,
            to: data.x,
            easing: 'easeOutQuart'
          },
          x2: {
            begin: delay,
            dur: durations,
            from: data.x - 10,
            to: data.x,
            easing: 'easeOutQuart'
          },
          opacity: {
            begin: delay,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'easeOutQuart'
          }
        });

      } else if(data.type === 'grid') {

        // Using data.axis we get x or y which we can use to construct our animation definition objects

        seq++;

        data.element.addClass('pt-' + data.index);

        if(data.axis.units.dir == 'vertical') {

        var delay = (seq * delays) - delayoffset;

        if(!caddy.evergreen || !caddy.animateGraph) delay = 0;

        if(data.index == 0) {
          setTimeout(function () {
              $('.ct-chart').addClass('draw-lines');
          }, delay);
        }

        if(!caddy.evergreen || !caddy.animateGraph) return false;


          var pos1Animation = {
            begin: delay,
            dur: durations,
            from: data[data.axis.units.pos + '1'] - 30,
            to: data[data.axis.units.pos + '1'],
            easing: 'easeOutQuart'
          };

          var pos2Animation = {
            begin: delay,
            dur: durations,
            from: data[data.axis.units.pos + '2'] - 100,
            to: data[data.axis.units.pos + '2'],
            easing: 'easeOutQuart'
          };

          var animations = {};
          animations[data.axis.units.pos + '1'] = pos1Animation;
          animations[data.axis.units.pos + '2'] = pos2Animation;
          animations['opacity'] = {
            begin: delay,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'easeOutQuart'
          };

          data.element.animate(animations);

        }

      }

    });

    chart.on('created', function (data) {
      seq = 0;

      if(caddy.animateGraph == true) {
        caddy.animateGraph = false;
      }



      var vLineFirst = document.querySelector('.ct-grid.ct-vertical'),
        hLineFirst = document.querySelector('.ct-grid.ct-horizontal');

      vLineFirst.classList.add("ct-vertical--first");
      hLineFirst.classList.add("ct-horizontal--first");

      document.querySelector('.chart').classList.add('created');

    });

    $('.ct-chart').on('mouseover', function (e) {
      document.querySelector('.chart').classList.add('hover--active');
    });

    $('.ct-chart').on('mouseout', function (e) {
      document.querySelector('.chart').classList.remove('hover--active');
      var elems = document.querySelectorAll('.ct-circle');
      [].forEach.call(elems, function (el) {
        el.classList.remove('active');
      });
    });



    $(".ct-chart").mousemove(function (event) {


      var mX = event.pageX - caddy.chartOffset,
        pointIndex = closestArrayIndex(caddy.aPoints, mX),
        point = document.querySelector('.ct-circle.pt-' + pointIndex);

        // Active style on closest circle(s) by x
        var myNodeList = document.querySelectorAll('.ct-circle');
        forEach(myNodeList, function (index, value) {
            value.classList.remove('active');
        });
        // Show on active closest circle on all devices
        var myNodeList = document.querySelectorAll('.ct-circle.pt-' + pointIndex);
        forEach(myNodeList, function (index, value) {
            value.classList.add('active');
        });


        // Active style on vertical grid lines
        var myNodeList = document.querySelectorAll('.ct-grid');
        forEach(myNodeList, function (index, value) {
            value.classList.remove('active');
        });
        document.querySelector('.ct-grid.pt-' + pointIndex).classList.add('active');



    });
  };

  function responsiveJS () {

    caddy.scTablet.addListener(function (e) {

      drawChart(caddy.chartData);

    });
  }

  function closestArrayIndex(array,num) {
    var i =0;
    var minDiff=1000;
    var ans;
    for(i in array){
         var m = Math.abs(num-array[i]);
         if(m<minDiff){
                minDiff=m;
                ans=i;
            }
      }
    return ans;
  }

  function chartToggle() {
    $('.ch-list__link').on('click', function(e) {
      e.preventDefault();
      $('.ch-list__link').removeClass('active');
      $(this).toggleClass('active');

      $('.ct-chart').removeClass('draw-lines');
      $('.ct-chart').removeClass('draw-circles');

      $('.ct-chart').removeClass('chart--1');
      $('.ct-chart').removeClass('chart--2');
      $('.ct-chart').addClass('chart--' + ($(this).parent().index() + 1));

      drawChart(caddy.chartData);

    });
  }

  function browserDetection () {

    if(bowser.webkit || bowser.blink || bowser.firefox) {
      caddy.evergreen = true;
    }

    // Safari
    if (bowser.webkit) {
      return true;
    }

    // Mac Chrome / Opera
    if (bowser.mac && bowser.blink) {
      return true;
    }

    // IOS
    if (bowser.ios) {
      return true;
    }

    // Others
    document.body.classList.add('b-fix');

  }

  function panelTabs () {
    $('.panel-tabs__link').on('click', function(e) {

      e.preventDefault();

      $('.panel-tabs__item').removeClass('active');
      $(this).parent().addClass('active');

      var index = $(this).parent().index() + 1;

      $('.panel').removeClass('active');

      $('.panel--' + index).addClass('active');
    });
  }

  function applicationStartup () {
     var orgTitle = document.querySelector('.org__title');
     orgTitle.innerHTML = CONFIG.orgTitle;
     orgTitle.classList.add('active');
  }


  function pad(n) {
    return (n < 10) ? ("0" + n) : n;
  }

  // forEach method, could be shipped as part of an Object Literal/Module
  function forEach (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
      callback.call(scope, i, array[i]); // passes back stuff we need
    }
  }

  function initOdometers () {
    var counter1 = new Odometer({
        el: document.querySelector(".contact--hours"),
        minIntegerLen: 2,
        format: "(,ddd)",
        theme: "default",
        value: 00
    });

    var counter2 = new Odometer({
        el: document.querySelector(".contact--minutes"),
        minIntegerLen: 2,
        format: "(,ddd)",
        theme: "default",
        value: 00
    });

    var counter3 = new Odometer({
        el: document.querySelector(".v-card__total"),
        minIntegerLen: 2,
        format: "(,ddd)",
        theme: "default",
        value: 00
    });
  }

  function animationEvents () {
    $(document).on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', '.card-list--live', function (e) {
      //console.log('sup');
    });
  }

  function updateTimestamps () {

    $('[data-time]').each(function (index) {

      var now = moment(Date.now()),
        oldest = moment($(this).data('time')),
        diff = now.diff(oldest,'seconds');

        if(diff < 3) {
           $(this).find('.card__nbr').html('');
          $(this).find('.card__lbl').html('Right now');
        } else if (diff < 60) {
          $(this).find('.card__nbr').html(diff);
          $(this).find('.card__lbl').html('seconds ago');

        } else if(diff >= 60 && diff < 120) {
          $(this).find('.card__nbr').html(now.diff(oldest,'minutes'));
          $(this).find('.card__lbl').html('minute ago');

        } else if(diff >= 120) {
          $(this).find('.card__nbr').html(now.diff(oldest,'minutes'));
          $(this).find('.card__lbl').html('minutes ago')

        }

    });
  }

  function shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = array[i];
          array[i] = array[j];
          array[j] = temp;
      }
      return array;
  }


  function showPage (page, interval) {

    var hash;

    setTimeout( function () {



      hash = (page.PageName + page.DeviceType).hashCode();

      if (page.DeviceType === 'mobile') {
        page.DeviceType = 'phone';
      }

      page.timestamp = Date.now();


      if (hash != caddy.lastLiveHash) {
        //console.log('New Live Page');

        caddy.lastLiveHash = hash;


        var template = $('#card1first').html(),
        rendered,
        renderedFirst;


        // First Card

        template = $('#card1first').html();
        Mustache.parse(template);
        renderedFirst = Mustache.render(template, page);
        $('.panel--1 .card--first').html(renderedFirst);



        if(caddy.firstLiveCard) {
          caddy.firstLiveCard = false;

          // Card List
          template = $('#card1').html();
          Mustache.parse(template);
          rendered = Mustache.render(template, page);
          $('.card-list--live').prepend(rendered);


        } else {

          // Add new card and animate

          var duration = 0;

          // only use timers if on big screen
          if (caddy.scBig.matches) {
            duration = 900;
          }

          $('.card-list--live').addClass('slide');

          // Move card list back to the top and add the hidden first card
          setTimeout(function () {

            $('.card-list--live').removeClass('slide');
            $('.card-list--live').addClass('slideback');

            template = $('#card1').html();
            Mustache.parse(template);
            rendered = Mustache.render(template, page);

            $('.card-list--live').prepend(rendered);

            updateTimestamps();

            // Reset class list and remove 11th card
            setTimeout(function () {
              $('.card-list--live').removeClass('slideback');
              $('.card-list--live .card:nth-child(11)').remove();

            }, duration)


          },duration);
        }
      }

      updateTimestamps();
    }, interval);


  }



}());


