console.log('jon.js');


function abbrNum(number, decPlaces) {
  // 2 decimal places => 100, 3 => 1000, etc

  if(!decPlaces) {
    decPlaces = 0;
  }

  decPlaces = Math.pow(10,decPlaces);

  // Enumerate number abbreviations
  var abbrev = [ "k", "m", "b", "t" ];

  // Go through the array backwards, so we do the largest first
  for (var i=abbrev.length-1; i>=0; i--) {

      // Convert array index to "1000", "1000000", etc
      var size = Math.pow(10,(i+1)*3);

      // If the number is bigger or equal do the abbreviation
      if(size <= number) {
           // Here, we multiply by decPlaces, round, and then divide by decPlaces.
           // This gives us nice rounding to a particular decimal place.
           number = Math.round(number*decPlaces/size)/decPlaces;

           // Handle special case where we round up to the next abbreviation
           if((number == 1000) && (i < abbrev.length - 1)) {
               number = 1;
               i++;
           }

           // Add the letter for the abbreviation
           number += abbrev[i];

           // We are done... stop
           break;
      }
  }

  return number;
}

// Turn minutes to array with hours and minutes
function minsAndHours(a){
  var hours =  Math.floor( a / 60);
  var minutes = a % 60;

  return [hours,minutes]
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getRandomIntervals (numberOfEvents, millisecondsToDisperseOver, startingNow)
{
  var values = [];
  var proportions = [];
  var totalProp = 0;
  for(var i = 0; i < numberOfEvents; i++)
  {
    var rand = 1 + (Math.random() * 5);//Create a proportional buffer, so we don't end up with events happening within milliseconds of each other
    totalProp += rand;
    proportions.push(totalProp);
  }

  var multiplier = millisecondsToDisperseOver/totalProp;

  if(startingNow === true)
  {
    values.push(0);
  }

  var i = 0;
  while(values.length < numberOfEvents)
  {
    values.push(Math.round(proportions[i] * multiplier));
    i++;
  }
  return values;

}


String.prototype.hashCode = function() {
  var hash = 0, i, chr, len;
  if (this.length === 0) return hash;
  for (i = 0, len = this.length; i < len; i++) {
    chr   = this.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
